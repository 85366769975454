<template>
  <div class="container">
    <AssignmentbarFITiFe @end-assignment="alert('endAssignment')"
    :showHelpBtn="false"
    :showDismissBtn="true"
    :showFinishBtn="false">
    </AssignmentbarFITiFe>
    <div class="row">
      <h2>B3. Fähigkeit zum effizienten Arbeiten</h2>
      <p>
        Im Informatikstudium hat man ein umfangreiches Aufgabenpensum zu erledigen. Das betrifft vor allem die Vor- und Nachbereitung der Lehrveranstaltungen und Übungen, das Literaturstudium, das Erstellen von Abgaben für praktische Arbeiten oder Implementierungen sowie das selbstständige Verfassen von Texten. Man muss deshalb in der Lage sein, gut organisiert, rationell und diszipliniert zu arbeiten. 
      </p>
      <div class = "survey">
        <div class="survey-row header">
          <div class = "question">Wie ist das bei ihr/ihm?</div>
          <div class = "reply">trifft völlig zu</div>
          <div class = "reply">trifft überwiegend zu</div>
          <div class = "reply">trifft teilsteils zu</div>
          <div class = "reply">trifft überwiegend nicht zu</div>
          <div class = "reply">trifft überhaupt nicht zu</div>
          <div class = "reply">nicht beurteilbar</div>
        </div>

        <div class="survey-row light">
          <div class = "question">Sie/Er versteht es gut, sich die Arbeit einzuteilen.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "0" v-model = "fragen.Q1"></div>
        </div>

        <div class="survey-row dark">
          <div class = "question">Wenn mehrere Aufgaben zu bewältigen sind, fällt es ihr/ihm schwer, Prioritäten zu setzen.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "0" v-model = "fragen.Q2"></div>
        </div>

        <div class="survey-row light">
          <div class = "question">Es gelingt ihr/ihm sehr gut, mit ihrer/seiner Arbeit in der vorgegebenen Zeit fertig zu werden.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "0" v-model = "fragen.Q3"></div>
        </div>

        <div class="survey-row dark">
          <div class = "question">Sie/Er fürchtet, dass sie/er nicht rationell genug arbeitet.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "0" v-model = "fragen.Q4"></div>
        </div>

        <div class="survey-row light">
          <div class = "question">Sie/Er gerät fast nie in Zeitdruck. </div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "0" v-model = "fragen.Q5"></div>
        </div>

        <div class="survey-row dark">
          <div class = "question">Sie/Er lässt sich leicht von ihrer/seiner Arbeit ablenken. </div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q6"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q6"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q6"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q6"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q6"></div>
          <div class = "reply"><input type = "radio" value = "0" v-model = "fragen.Q6"></div>
        </div>

    </div>
    <div class="row notification-message">
      <p v-if="missingAnswers >= 2">
        Sie haben mehrere Fragen eines Merkmalsbereichs mit "nicht beurteilbar"
        beantwortet. Die Auswertung führt nur dann zu einem Ergebnis für diesen
        Bereich, wenn Sie nicht mehr als zwei Fagen in dieser Weise beantworten.
        Gehen Sie nur weiter, wenn Sie sich Ihrer Antworten sicher sind.
      </p>
      <p v-if="missingAnswers == 1">
        Sie haben eine Frage mit "nicht beurteilbar" beantwortet. Gehen Sie nur
        zur nächsten Seite weiter, wenn Sie sich Ihrer Antworten sicher sind.
      </p>
    </div>
  </div>
</div>
</template>

<script>
import AssignmentbarFITiFe from "@/components/inf/fiti_fe/AssignmentbarFITiFe.vue";
import * as scrolling from "@/helpers/scroll-helper.js";
import store from "@/store";

export default {
  name: "FitiFeB3",
  components: {
    AssignmentbarFITiFe
},
  emits: ["enableWeiter", "disableWeiter"],
  mounted() {
    scrolling.scrollToElementByClassName("container", { behavior: "smooth" }); 
    this.fragen.Q1 = this.$store.state.fitiFeModule.replies.B3[0];
    this.fragen.Q2 = this.$store.state.fitiFeModule.replies.B3[1];
    this.fragen.Q3 = this.$store.state.fitiFeModule.replies.B3[2];
    this.fragen.Q4 = this.$store.state.fitiFeModule.replies.B3[3];
    this.fragen.Q5 = this.$store.state.fitiFeModule.replies.B3[4]; 
    this.fragen.Q6 = this.$store.state.fitiFeModule.replies.B3[5];
    this.$emit("disableWeiter");
  },
  updated() {
    if (this.unfinishedAnswers == 0) {
      this.$emit("enableWeiter");
    }
  },
  created() {
    // add watcher to count missing answers
    for (const key in this.fragen) {
      this.$watch(`fragen.${key}`, (newValue, oldValue) => {
        if(newValue==0){
          this.missingAnswers++;
        }
        if(oldValue==0 && newValue!=0){
          this.missingAnswers--;
        }
        if((oldValue == null || oldValue == undefined) && (newValue != null && newValue != undefined)){
          this.unfinishedAnswers--;
        }
      });
    }
  },
  unmounted() {
    if (this.unfinishedAnswers == 0) {
      this.logUpdate(this.fragen);
    }
  },
  data() {
    return {
      fragen: {
        Q1: null,
        Q2: null,
        Q3: null,
        Q4: null,
        Q5: null,
        Q6: null
      },
      missingAnswers: 0,
      unfinishedAnswers: 6
    };
  },
  methods: {
  logUpdate(data) {
      store.commit("fitiFeB3Update", data);
    },
  }
}
</script>

<style>
  .survey {
  display: flex;
  flex-direction: column;
  }
  .survey-row {
    display: flex;
    flex-direction: row;
  }
  .question {
    min-width: 100px;
    width: 25%;
    max-width: 500px;
  }
  .reply {
    min-width: 100px;
    width: 15%;
    max-width: 300px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .dark {
    background-color: rgb(173, 201, 230);
  }
  .light{
    background-color: rgb(248, 248, 248);
  }
  .header {
    background: rgb(70, 110, 165);
    color: white;
  }
</style>