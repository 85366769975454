<template>
  <div class="container" v-if="dataReady">
    <div
      style="
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        z-index: 1000;
      "
      v-if="false"
    >
    <div style="background-color: white">
    CURRENT TAB: {{ currentTab }}</div>
    <button @click="changeCurrentTab(0)">A1</button>
    <button @click="changeCurrentTab(1)">A2</button>
    <button @click="changeCurrentTab(2)">A3</button>
    <button @click="changeCurrentTab(3)">B1</button>
    <button @click="changeCurrentTab(4)">B2</button>
    <button @click="changeCurrentTab(5)">B3</button>
    <button @click="changeCurrentTab(6)">C1</button>
    <button @click="changeCurrentTab(7)">C2</button>
    <button @click="changeCurrentTab(8)">D1</button>
    <button @click="changeCurrentTab(9)">D2</button>
    <button @click="changeCurrentTab(10)">Res</button>
    </div>

    <div class="welcome-message" v-if="!feStarted && !feFinished">
      <h1>Fremdeinschätzung</h1>
      <p>Sehr geehrte Dame, sehr geehrter Herr,</p>
      <p>
        mit dem Fragebogen „FIT für das Informatikstudium“ (FIT-I) können Sie
        einschätzen, inwieweit eine Person den grundlegenden Anforderungen des
        Informatikstudiums und des Berufs als Informatiker:in gerecht wird. Es
        geht dabei nicht um das fachliche Wissen, sondern um die persönlichen
        Voraussetzungen, die Anforderungen des Informatikstudiums und des Berufs
        als Informatiker:in bewältigen zu können.
      </p>
      <p>
        Es liegt dazu bereits eine Selbsteinschätzung vor. Ihre
        Fremdeinschätzung soll helfen, weiteren Entwicklungsbedarf zu erkennen
        und konkrete Entwicklungsbemühungen zu unternehmen.
      </p>

      <p>
        In diesem Sinne kommt einem anschließenden gemeinsamen Gespräch eine
        wichtige Funktion zu. Hier gilt es, Selbst- und Fremdeinschätzung
        zusammenzuführen und zum Gegenstand gemeinsamer Überlegungen zu machen.
      </p>
      <p>
        Ihre Einschätzung können Sie anhand der vorgegebenen Aussagen vornehmen.
        Es steht Ihnen dazu eine fünfstufige Skala mit folgenden Ausprägungen
        zur Verfügung:
      </p>
      <ul>
        <li>Die Aussage trifft völlig auf sie/ihn zu.</li>
        <li>Die Aussage trifft überwiegend auf sie/ihn zu.</li>
        <li>Die Aussage trifft teil-teils auf sie/ihn zu.</li>
        <li>Die Aussage trifft überwiegend nicht auf sie/ihn zu.</li>
        <li>Die Aussage trifft überhaupt nicht auf sie/ihn zu.</li>
      </ul>
      <p>
        Nicht bei allen Punkten wird Ihnen das Urteil leichtfallen. Manchmal
        werden Sie nicht umhinkommen, Erfahrungen aus einer konkreten Situation
        auf eine andere, nicht direkt beobachtete zu übertragen. Sollte es
        einmal vorkommen, dass Sie auch beim besten Willen kein Urteil treffen
        können, klicken Sie auf „nicht beurteilbar“. Bedenken Sie aber, dass die
        Person, zu der Sie Ihre Einschätzung abgeben, dann im jeweiligen Bereich
        eventuell kein Feedback erhalten wird.
      </p>

      <p>
        Beachten Sie bei der Auswahl der Skalenstufen, dass die Aussagen zum
        Teil positiv und zum Teil negativ formuliert sind. Und bitte bemühen Sie
        sich um eine möglichst realistische Einschätzung, denn nur dann kann die
        an Ihren Urteilen interessierte Person die richtigen Schlussfolgerungen
        für sich ableiten.
      </p>
      <p>
        Sollten Sie Ihre Antwort einmal korrigieren wollen, klicken Sie einfach
        auf das richtige Feld. Nach dem Abschluss der Bearbeitung des
        Fragebogens können Sie Ihre Antworten allerdings nicht mehr verändern.
      </p>
      <p>
        Eine vollständige Bearbeitung der Fremdeinschätzung wird ungefähr 20 Minuten in Anspruch nehmen. Nehmen Sie sich ausreichend Zeit, da Zwischenergebnisse nicht gespeichert werden. Um mit der Fremdeinschätzung zu starten verwenden Sie den Code, der für Sie bereitgestellt wurde.
        Sollten Sie eine Einladung zur Fremdeinschätzung per Mail erhalten haben, finden Sie diesen in der Mail, die Ihnen zugesandt wurde.
        Alternativ kann die Person, zu der Sie eine Einschätzung abgeben, Ihren Code im Modul „FIT-I“ abrufen. Bitten Sie diese Person Ihnen Ihren Code mitzuteilen.
      </p>
      <p class="important">
        Bitte überprüfen Sie Ihren Code, da eine korrekte Zuordnung Ihrer
        Fremdeinschätzung sonst nicht möglich ist.
      </p>

      <div class="code-input">
        <input type="text" v-model="myFeCode" placeholder="Ihr Code" />
        <div
          class="custom-btn"
          :class="{ 'custom-btn-disabled': myFeCode.length != 10 }"
          @click="startFeBtnClicked"
        >
          Fremdeinschätzung starten
        </div>
      </div>
      <div class="invalid-code" v-if="invalidCode">
        Der eingegebene Code ist ungültig. Bitte versuchen Sie es erneut.
      </div>
    </div>

    <div class="fe-tab-container" v-if="feStarted">
      <component
        @enable-weiter="enableContinue"
        @disable-weiter="disableContinue"
        :my-fe-id="myFeID"
        :my-fe-code="myFeCode"
        :is="tabs[currentTab]"
      />
      <div class="unfinished-answers-message" v-if="showUnfinishedAnswersMessage">
        Um fortzufahren müssen Sie alle Fragen beantworten. Sollten Sie kein Urteil treffen
        können, klicken Sie auf „nicht beurteilbar“.
      </div>
      <div
        class="custom-btn"
        @click="forwardBtnClicked"
        :class="{
          'custom-btn-disabled':
            !nextTabEnabled && currentTab < tabs.length - 1 ? true : false,
        }"
        v-if="currentTab < tabs.length - 2"
      >
        Weiter
      </div>
      <div class="custom-btn"
      @click="forwardBtnClicked"
      v-if="currentTab == tabs.length - 2">
        Fremdeinschätzung abschließen
      </div>
      <div
        class="custom-btn"
        @click="finishBtnClicked"
        v-if="currentTab == tabs.length - 1"
      >
        Fremdeinschätzung schließen
      </div>
      
      <div
        class="custom-btn-secondary"
        @click="backBtnClicked"
        v-if="currentTab > 0 && (currentTab != tabs.length-1)"
      >
        Zurück
      </div>
    </div>

    <div class="finish-message" v-if="feFinished">
      Vielen Dank für Ihre Antworten! Sie haben die Fremdeinschätzung
      abgeschlossen und können diese Seite nun schließen.
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
const store = useStore();

const dataReady = ref(false);
const feFinished = ref(false);
const feStarted = ref(false);

onMounted(() => {
  store.dispatch("setUser", {}); // set User to empty {} for TheHeader
  dataReady.value = true;
});

const myFeCode = ref("");
const myFeID = ref(0);
const invalidCode = ref(false);

const getMyFeID = async () => {
  let res = null;
  let myData = {
    myFeCode: myFeCode.value,
  };
  let objJSON = JSON.stringify({ data: myData });
  try {
    const response = await fetch(
      process.env.VUE_APP_STRAPI_API_URL + "/api/inf-fiti-fe/get-my-fe",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: objJSON,
      }
    );
    res = await response.json();
  } catch (error) {
    console.error(error);
    invalidCode.value = true;
    return;
  }
  
  if (res.myFe.length > 1 || res.myFe.length == 0 || res.myFe == 'not found' || res.myFe[0].code != myFeCode.value || typeof res.myFe[0].id != 'number') {
    invalidCode.value = true;
    return;
  }
  if(res.myFe[0].replies != null){
    alert("Die Fremdeinschätzung wurde bereits abgeschlossen.")
    currentTab.value = tabs.length - 1; // go to last tab => show results
  }
  
  myFeID.value = res.myFe[0].id;
  invalidCode.value = false;
  feStarted.value = true;
};

const finishBtnClicked = async () => {
  feFinished.value = true;
  feStarted.value = false;
};

 const saveFitiFeReplies = async () => {
  let params = {
    myFeId: myFeID.value,
    myFeCode: myFeCode.value,
    replies: store.getters.getFitiFeModule.replies,
  }

  let res = null;
    try {
      const response = await fetch(
        process.env.VUE_APP_STRAPI_API_URL + "/api/inf-fiti-fes/"+myFeID.value,
        {
          method: "PUT",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({data: params})
          }
      );
      res = await response.json();  
      } catch (error) {
        console.error(error)
      }
    return res;
    }


import A1 from "@/components/inf/fiti_fe/A1.vue";
import A2 from "@/components/inf/fiti_fe/A2.vue";
import A3 from "@/components/inf/fiti_fe/A3.vue";
import B1 from "@/components/inf/fiti_fe/B1.vue";
import B2 from "@/components/inf/fiti_fe/B2.vue";
import B3 from "@/components/inf/fiti_fe/B3.vue";
import C1 from "@/components/inf/fiti_fe/C1.vue";
import C2 from "@/components/inf/fiti_fe/C2.vue";
import D1 from "@/components/inf/fiti_fe/D1.vue";
import D2 from "@/components/inf/fiti_fe/D2.vue";
import Conclusion from "@/components/inf/fiti_fe/Conclusion.vue";
import Results from "@/components/inf/fiti_fe/Results.vue";
const tabs = [A1, A2, A3, B1, B2, B3, C1, C2, D1, D2, Conclusion, Results];
const currentTab = ref(0);
const nextTabEnabled = ref(false);

const showUnfinishedAnswersMessage = ref(false);

async function forwardBtnClicked() {
  if (!nextTabEnabled.value) {
    showUnfinishedAnswersMessage.value = true;
    return;
  }
  if(currentTab.value == tabs.length - 2){
    // if tab is D2, save replies 
    await saveFitiFeReplies();
  }
  showUnfinishedAnswersMessage.value = false;
  currentTab.value++;
}
function backBtnClicked() {
  enableContinue();
  showUnfinishedAnswersMessage.value = false;
  currentTab.value--;
}

const startFeBtnClicked = async () => {
  await getMyFeID();
  
  if (!invalidCode.value ) {
    feFinished.value = false;
    feStarted.value = true;
  }
};

function enableContinue() {
  nextTabEnabled.value = true;
}

function disableContinue() {
  nextTabEnabled.value = false;
}

const changeCurrentTab = (index) => {
  feStarted.value = true;
  currentTab.value = index;
}
</script>

<style>
.important {
  font-weight: bold;
  background-color: lightblue;
  border: 1px solid gray;
  border-radius: 0.5em;
  padding: 0.4em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.code-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

button{
  width: 2em;
}

.notification-message {
  display: flex; 
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
  font-style: italic;
  font-weight: bolder;
}

.unfinished-answers-message{
  display: flex; 
  justify-content: center;
  align-items: center;
  border: 2px solid darkred;
  padding: 0.4em;
  margin-bottom: 1em;
}

.custom-btn, .custom-btn-secondary {
  float: right;
}
</style>
