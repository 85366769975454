<template>
  <div class="container">
    <AssignmentbarFITiFe @end-assignment="alert('endAssignment')"
    :showHelpBtn="false"
    :showDismissBtn="true"
    :showFinishBtn="false">
    </AssignmentbarFITiFe>
    <div class="row">
      <h2>B2. Selbstständigkeit und Eigenaktivität</h2>
      <p>
        Sowohl im Informatikstudium als auch im Beruf sind Selbstständigkeit und Eigenaktivität gefragt. Das betrifft die Studien- bzw. Arbeitsplanung, die Ausführung und die Qualitätskontrolle der Ergebnisse. Hohe Selbstständigkeit und Eigenaktivität sind auch eine wesentliche Voraussetzung für eine zügige und erfolgreiche persönliche Entwicklung in Studium und Arbeit. 
      </p>
      <div class = "survey">
        <div class="survey-row header">
          <div class = "question">Wie ist das bei ihr/ihm?</div>
          <div class = "reply">trifft völlig zu</div>
          <div class = "reply">trifft überwiegend zu</div>
          <div class = "reply">trifft teilsteils zu</div>
          <div class = "reply">trifft überwiegend nicht zu</div>
          <div class = "reply">trifft überhaupt nicht zu</div>
          <div class = "reply">nicht beurteilbar</div>
        </div>

        <div class="survey-row light">
          <div class = "question">Für sie/ihn ist es besser, wenn man ihr/ihm genau sagt, was sie/er tun soll.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "0" v-model = "fragen.Q1"></div>
        </div>

        <div class="survey-row dark">
          <div class = "question">Wenn die Arbeitsaufträge erledigt sind, ist für sie/ihn Schluss mit der Arbeit.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "0" v-model = "fragen.Q2"></div>
        </div>

        <div class="survey-row light">
          <div class = "question">Ohne klare Vorgaben weiß sie/er oft nicht, wie sie/er arbeiten soll.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "0" v-model = "fragen.Q3"></div>
        </div>

        <div class="survey-row dark">
          <div class = "question">Es überfordert sie/ihn, wenn sie/er viele Dinge gleichzeitig erledigen soll.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "0" v-model = "fragen.Q4"></div>
        </div>

        <div class="survey-row light">
          <div class = "question">Arbeitsaufträge ohne detaillierte Vorgaben schiebt sie/er gern vor sich her. </div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "0" v-model = "fragen.Q5"></div>
        </div>

    </div>
    
    <div class="row notification-message">
      <p v-if="missingAnswers >= 2">
        Sie haben mehrere Fragen eines Merkmalsbereichs mit "nicht beurteilbar"
        beantwortet. Die Auswertung führt nur dann zu einem Ergebnis für diesen
        Bereich, wenn Sie nicht mehr als eine Fage in dieser Weise beantworten.
        Gehen Sie nur weiter, wenn Sie sich Ihrer Antworten sicher sind.
      </p>
      <p v-if="missingAnswers == 1">
        Sie haben eine Frage mit "nicht beurteilbar" beantwortet. Die Auswertung führt nur dann zu einem Ergebnis für diesen
        Bereich, wenn Sie nicht mehr als eine Fage in dieser Weise beantworten.
        Gehen Sie nur weiter, wenn Sie sich Ihrer Antworten sicher sind.
      </p>
    </div>
  </div>
</div>
</template>

<script>
import AssignmentbarFITiFe from "@/components/inf/fiti_fe/AssignmentbarFITiFe.vue";
import * as scrolling from "@/helpers/scroll-helper.js";
import store from "@/store";

export default {
  name: "FitiFeB2",
  components: {
    AssignmentbarFITiFe
},
  emits: ["enableWeiter", "disableWeiter"],
  mounted() {
    scrolling.scrollToElementByClassName("container", { behavior: "smooth" });
    this.fragen.Q1 = this.$store.state.fitiFeModule.replies.B2[0];
    this.fragen.Q2 = this.$store.state.fitiFeModule.replies.B2[1];
    this.fragen.Q3 = this.$store.state.fitiFeModule.replies.B2[2];
    this.fragen.Q4 = this.$store.state.fitiFeModule.replies.B2[3];
    this.fragen.Q5 = this.$store.state.fitiFeModule.replies.B2[4]; 
    this.$emit("disableWeiter");
  },
  updated() {
    if (this.unfinishedAnswers == 0) {
      this.$emit("enableWeiter");
    }
  },
  created() {
    // add watcher to count missing answers
    for (const key in this.fragen) {
      this.$watch(`fragen.${key}`, (newValue, oldValue) => {
        if(newValue==0){
          this.missingAnswers++;
        }
        if(oldValue==0 && newValue!=0){
          this.missingAnswers--;
        }        
        if((oldValue == null || oldValue == undefined) && (newValue != null && newValue != undefined)){
          this.unfinishedAnswers--;
        }
      });
    }
  },
  unmounted() {
    if (this.unfinishedAnswers == 0) {
      this.logUpdate(this.fragen);
    }
  },
  data() {
    return {
      fragen: {
        Q1: null,
        Q2: null,
        Q3: null,
        Q4: null,
        Q5: null
      },
      missingAnswers: 0,
      unfinishedAnswers: 5
    };
  },
  methods: {
    logUpdate(data) {
      store.commit("fitiFeB2Update", data);
    },
  }
}
</script>

<style>
  .survey {
  display: flex;
  flex-direction: column;
  }
  .survey-row {
    display: flex;
    flex-direction: row;
  }
  .question {
    min-width: 100px;
    width: 25%;
    max-width: 500px;
  }
  .reply {
    min-width: 100px;
    width: 15%;
    max-width: 300px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .dark {
    background-color: rgb(173, 201, 230);
  }
  .light{
    background-color: rgb(248, 248, 248);
  }
  .header {
    background: rgb(70, 110, 165);
    color: white;
  }
</style>
