<template>
  <div class="container">
    <AssignmentbarFITiFe @end-assignment="alert('endAssignment')"
    :showHelpBtn="false"
    :showDismissBtn="true"
    :showFinishBtn="false">
    </AssignmentbarFITiFe>    
    <div class="animate glow row">
      <h2>FIT-I Fremdeinschätzung Abschluss</h2>
      <p>
        Vielen Dank für Ihre Einschätzung! Auf der nächsten Seite können Sie die Ergebnisse Ihrer 
        Antworten einsehen. Schließen Sie die Fremdeinschätzung ab um Ihre Auswertung für die eingeschätze Person verfügbar zu machen.
        Danach können Sie Ihre Antworten nicht mehr verändern.
        
      </p>
      <p>
        Im Anschluss empfehlen wir zusätzlich ein Auswertungsgepräch mit der eingeschätzten Person durchzuführen. 
      </p>
    </div>
  </div>
</template>

<script>
import AssignmentbarFITiFe from "@/components/inf/fiti_fe/AssignmentbarFITiFe.vue";
import * as scrolling from "@/helpers/scroll-helper.js";

export default {
  name: "FitiFeConclusion",
  components: {
    AssignmentbarFITiFe
},
  emits: ["enableWeiter"],
  mounted() {
    scrolling.scrollToElementByClassName("container", { behavior: "smooth" }); 
    this.$emit("enableWeiter");
  },
  data() {
    return {};
  },
  methods: {
  },
  computed: {
  }
};
</script>

<style>
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
p{
  line-height: 1.3;
}
</style>
