<template>
  <div class="container">
    <AssignmentbarFITiFe
      @end-assignment="alert('endAssignment')"
      :showHelpBtn="false"
      :showDismissBtn="true"
      :showFinishBtn="false"
    >
    </AssignmentbarFITiFe>
    <div class="row">
      <h2>A1: Neugier und Begeisterungsfähigkeit</h2>
      <p>
        Informatiker:innen entwickeln Werkzeuge und Lösungen für die
        unterschiedlichsten Arbeitsfelder und Erfordernisse. Das erfordert die
        Bereitschaft, sich mit fremden Themen und Arbeitsweisen gründlich
        auseinanderzusetzen. Hilfreich sind dabei Neugier und
        Begeisterungsfähigkeit.
      </p>
      <div class="survey">
        <div class="survey-row header">
          <div class="question">Wie ist das bei ihr/ihm?</div>
          <div class="reply">trifft völlig zu</div>
          <div class="reply">trifft überwiegend zu</div>
          <div class="reply">trifft teilsteils zu</div>
          <div class="reply">trifft überwiegend nicht zu</div>
          <div class="reply">trifft überhaupt nicht zu</div>
          <div class="reply">nicht beurteilbar</div>
        </div>
        <div class="survey-row light">
          <div class="question">
            Sie/Er liebt es, knifflige Probleme zu durchdenken.
          </div>
          <div class="reply">
            <input type="radio" value="5" v-model="fragen.Q1" />
          </div>
          <div class="reply">
            <input type="radio" value="4" v-model="fragen.Q1" />
          </div>
          <div class="reply">
            <input type="radio" value="3" v-model="fragen.Q1" />
          </div>
          <div class="reply">
            <input type="radio" value="2" v-model="fragen.Q1" />
          </div>
          <div class="reply">
            <input type="radio" value="1" v-model="fragen.Q1" />
          </div>
          <div class="reply">
            <input type="radio" value="0" v-model="fragen.Q1" />
          </div>
        </div>
        <div class="survey-row dark">
          <div class="question">
            Für neue Entwicklungen und Trends kann sie/er sich schnell
            begeistern.
          </div>
          <div class="reply">
            <input type="radio" value="5" v-model="fragen.Q2" />
          </div>
          <div class="reply">
            <input type="radio" value="4" v-model="fragen.Q2" />
          </div>
          <div class="reply">
            <input type="radio" value="3" v-model="fragen.Q2" />
          </div>
          <div class="reply">
            <input type="radio" value="2" v-model="fragen.Q2" />
          </div>
          <div class="reply">
            <input type="radio" value="1" v-model="fragen.Q2" />
          </div>
          <div class="reply">
            <input type="radio" value="0" v-model="fragen.Q2" />
          </div>
        </div>
        <div class="survey-row light">
          <div class="question">
            Herausfordernde Aufgaben und Inhalte spornen sie/ihn sehr an.
          </div>
          <div class="reply">
            <input type="radio" value="5" v-model="fragen.Q3" />
          </div>
          <div class="reply">
            <input type="radio" value="4" v-model="fragen.Q3" />
          </div>
          <div class="reply">
            <input type="radio" value="3" v-model="fragen.Q3" />
          </div>
          <div class="reply">
            <input type="radio" value="2" v-model="fragen.Q3" />
          </div>
          <div class="reply">
            <input type="radio" value="1" v-model="fragen.Q3" />
          </div>
          <div class="reply">
            <input type="radio" value="0" v-model="fragen.Q3" />
          </div>
        </div>

        <div class="survey-row dark">
          <div class="question">
            Es macht ihr/ihm Freude, sich mit ihr/ihm unbekannten Themen zu
            beschäftigen.
          </div>
          <div class="reply">
            <input type="radio" value="5" v-model="fragen.Q4" />
          </div>
          <div class="reply">
            <input type="radio" value="4" v-model="fragen.Q4" />
          </div>
          <div class="reply">
            <input type="radio" value="3" v-model="fragen.Q4" />
          </div>
          <div class="reply">
            <input type="radio" value="2" v-model="fragen.Q4" />
          </div>
          <div class="reply">
            <input type="radio" value="1" v-model="fragen.Q4" />
          </div>
          <div class="reply">
            <input type="radio" value="0" v-model="fragen.Q4" />
          </div>
        </div>

        <div class="survey-row light">
          <div class="question">
            Begeisterung für Bereiche außerhalb seines/ihres Interessenprofils
            kann sie/er nur schwer entwickeln.
          </div>
          <div class="reply">
            <input type="radio" value="5" v-model="fragen.Q5" />
          </div>
          <div class="reply">
            <input type="radio" value="4" v-model="fragen.Q5" />
          </div>
          <div class="reply">
            <input type="radio" value="3" v-model="fragen.Q5" />
          </div>
          <div class="reply">
            <input type="radio" value="2" v-model="fragen.Q5" />
          </div>
          <div class="reply">
            <input type="radio" value="1" v-model="fragen.Q5" />
          </div>
          <div class="reply">
            <input type="radio" value="0" v-model="fragen.Q5" />
          </div>
        </div>

        <div class="survey-row dark">
          <div class="question">
            Neue Themen sind ihr/ihm immer eine willkommene Abwechslung.
          </div>
          <div class="reply">
            <input type="radio" value="5" v-model="fragen.Q6" />
          </div>
          <div class="reply">
            <input type="radio" value="4" v-model="fragen.Q6" />
          </div>
          <div class="reply">
            <input type="radio" value="3" v-model="fragen.Q6" />
          </div>
          <div class="reply">
            <input type="radio" value="2" v-model="fragen.Q6" />
          </div>
          <div class="reply">
            <input type="radio" value="1" v-model="fragen.Q6" />
          </div>
          <div class="reply">
            <input type="radio" value="0" v-model="fragen.Q6" />
          </div>
        </div>
      </div>
    </div>
    <div class="row notification-message">
      <p v-if="missingAnswers >= 2">
        Sie haben mehrere Fragen eines Merkmalsbereichs mit "nicht beurteilbar"
        beantwortet. Die Auswertung führt nur dann zu einem Ergebnis für diesen
        Bereich, wenn Sie nicht mehr als zwei Fagen in dieser Weise beantworten.
        Gehen Sie nur weiter, wenn Sie sich Ihrer Antworten sicher sind.
      </p>
      <p v-if="missingAnswers == 1">
        Sie haben eine Frage mit "nicht beurteilbar" beantwortet. Gehen Sie nur
        zur nächsten Seite weiter, wenn Sie sich Ihrer Antworten sicher sind.
      </p>
    </div>
  </div>
</template>

<script>
import AssignmentbarFITiFe from "@/components/inf/fiti_fe/AssignmentbarFITiFe.vue";
import * as scrolling from "@/helpers/scroll-helper.js";
import store from "@/store";

export default {
  name: "FitiFeA1",
  components: {
    AssignmentbarFITiFe,
  },
  emits: ["enableWeiter", "disableWeiter"],
  mounted() {
    scrolling.scrollToElementByClassName("container", { behavior: "smooth" });
    this.fragen.Q1 = this.$store.state.fitiFeModule.replies.A1[0];
    this.fragen.Q2 = this.$store.state.fitiFeModule.replies.A1[1];
    this.fragen.Q3 = this.$store.state.fitiFeModule.replies.A1[2];
    this.fragen.Q4 = this.$store.state.fitiFeModule.replies.A1[3];
    this.fragen.Q5 = this.$store.state.fitiFeModule.replies.A1[4];
    this.fragen.Q6 = this.$store.state.fitiFeModule.replies.A1[5];
    this.$emit("disableWeiter");
  },
  updated() {
    if (this.unfinishedAnswers == 0) {
      this.$emit("enableWeiter");
    }
  },
  created() {
    // add watcher to count missing answers
    for (const key in this.fragen) {
      this.$watch(`fragen.${key}`, (newValue, oldValue) => {
        if(newValue==0){
          this.missingAnswers++;
        }
        if(oldValue==0 && newValue!=0){
          this.missingAnswers--;
        }
        if((oldValue == null || oldValue == undefined) && (newValue != null && newValue != undefined)){
          this.unfinishedAnswers--;
        }
      });
    }
  },
  unmounted() {
    if (this.unfinishedAnswers == 0) {
      this.logUpdate(this.fragen);
    }
  },
  data() {
    return {
      fragen: {
        Q1: null,
        Q2: null,
        Q3: null,
        Q4: null,
        Q5: null,
        Q6: null,
      },
      missingAnswers: 0,
      unfinishedAnswers: 6
    };
  },
  methods: {
    logUpdate(data) {
      store.commit("fitiFeA1Update", data);
    },
  },
};
</script>

<style>
.survey {
  display: flex;
  flex-direction: column;
  line-height: 1.3;
}
.survey-row {
  display: flex;
  flex-direction: row;
}
.question {
  min-width: 100px;
  width: 25%;
  max-width: 500px;
  line-height: 1.6;
}
.reply {
  min-width: 100px;
  width: 15%;
  max-width: 300px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.dark {
  background-color: rgb(173, 201, 230);
}
.light {
  background-color: rgb(248, 248, 248);
}
.header {
  background: rgb(70, 110, 165);
  color: white;
}
p {
  line-height: 1.3;
}
</style>
