<template>
  <div class="container">
    <AssignmentbarFITiFe
      @end-assignment="alert('endAssignment')"
    ></AssignmentbarFITiFe>
    <div class="animate glow row">
      <h2>FIT für das Informatikstudium?! (FIT-I) - Fremdeinschätzung</h2>
      <h3>1. Allgemeines und das Ergebnisprofil Ihrer Fremdeinschätzung</h3>
      <p>
        Ihre Einschätzung (Fremdeinschätzung) soll die Selbsteinschätzung der
        Person ergänzen, die sich ein Bild zu ihren persönlichen Voraussetzungen
        für die Aufnahme eines Informatikstudiums und den Beruf Informatiker:in
        machen möchte. Eine solche Ergänzung ist zweckmäßig, weil bei einem
        Selbsturteil immer auch verschiedene Verzerrungstendenzen in Rechnung zu
        stellen sind. So kann einerseits die Neigung bestehen, sich in günstigem
        Licht und bezüglich der eigenen Möglichkeiten verklärt zu sehen.
        Andererseits herrscht bisweilen die umgekehrte Tendenz zur
        überkritischen Bewertung der eigenen Person vor. In beiden Fällen kann
        das Urteil einer außenstehenden Person, das einen Selbstbild-Fremdbild
        Abgleich ermöglicht, ein wichtiges Regulativ sein. Aus diesem Grunde
        haben wir Sie um Ihre Mitwirkung gebeten.
      </p>
      <p>
        Die Ergebnisse aus beiden Urteilen sollen nun der Person dabei helfen,
        gründlicher über die persönlichen Voraussetzungen für Studium und Beruf
        nachzudenken, Entwicklungsbedarf konkreter zu erkennen und
        Schlussfolgerungen für geeignete Entwicklungsschritte abzuleiten. In
        diesem Sinne kommt dem Gespräch, das Sie mit ihr führen sollten, eine
        wichtige Funktion zu.
      </p>
      <p>
        In der folgenden Darstellung können Sie die von Ihnen vorgenommene
        Einschätzung in Form eines Profils betrachten. (Das Profil der
        Selbsteinschätzung macht Ihnen die beurteilte Person im
        Auswertungsgespräch zugänglich.)
      </p>
      <p>
        Ganz grob lässt sich sagen, dass für fast alle Merkmale Werte über 4.0
        als günstige Einschätzungen der Eignungsvoraussetzungen zu verstehen
        sind. Werte unter 3.0 verweisen auf deutliche Schwachpunkte und
        bestehenden Entwicklungsbedarf. Eine Ausnahme bildet das Merkmal D.1.
        Resignationstendenz, bei dem niedrige Werte unter 2.0 wünschenswert und
        Werte über 3.0 kritisch zu bewerten sind.
      </p>
      <p class="box">
        Die hier verwendete neunstufige Standardskala baut auf der Erkenntnis
        auf, dass sich die meisten Merkmale in einer Gruppe normal verteilen,
        also entsprechend der „Gaußschen Glockenkurve“. Auch die Merkmale der
        Studierenden, die in die Entwicklung des FIT-I einbezogen wurden,
        verteilen sich in dieser Weise. Der Mittelwert auf dieser Skala ist 5.
        Auch die Werte 4 und 6 sind noch mittlere und somit durchschnittliche
        Werte. Insgesamt gilt der Durchschnittsbereich (4-6) für 54 % aller
        Personen. Die Werte 1-3 sind unterdurchschnittliche und 7-9
        überdurchschnittliche Werte. Sie gelten für jeweils 23 % der Personen.
        Die mittleren Werte kommen also weitaus häufiger vor als sehr geringe
        oder sehr hohe Werte. So liegen beispielsweise die Werte 1 und 9 nur bei
        4 % der gewählten Bezugsgruppe vor.
      </p>

      <!-- Grafik -->
      <canvas id="chart" style="width: 100%; max-height: 800px"></canvas>

      <div
        class="missing-answers-notification"
        v-if="showMissingAnswersNotification"
      >
        Achtung! Für einige Merkmale liegen keine Ergebnisse vor, weil nicht
        alle zugehörigen Items beantwortet wurden.
      </div>

      <h3>2. Auswertung Ihres Profils nach vier Bereichen</h3>
      <p>
        Bei der Betrachtung der Grafik werden Sie festgestellt haben, dass
        miteinander verwandte Merkmale zu inhaltlichen Schwerpunkten
        zusammengefasst wurden. Es lassen sich vier Bereiche unterscheiden, die
        als persönliche Eignungsvoraussetzungen für das Studium der Informatik
        und darüber hinaus auch für den Beruf von großer Bedeutung sind: A.
        Interesse und Leistungsanspruch, B. Auffassungsgabe und Arbeitsweise, C.
        Kooperationsbereitschaft und Kontaktfähigkeit und D. Belastbarkeit.
      </p>

      <div class="erklaerungsbox" v-if="this.dataReady">
        <div class="bereiche">
          <div
            @click="
              hideAllBereiche();
              bereiche.interesse = true;
            "
            :class="{ active: bereiche.interesse }"
            class="bereich"
          >
            <div class="letter">A</div>
            <div class="bereichsname">Interesse und Leistungsanspruch</div>
          </div>
          <div
            @click="
              hideAllBereiche();
              bereiche.auffassung = true;
            "
            class="bereich"
            :class="{ active: bereiche.auffassung }"
          >
            <div class="letter">B</div>
            <div class="bereichsname">Auffassungsgabe und Arbeitsweise</div>
          </div>
          <div
            @click="
              hideAllBereiche();
              bereiche.kooperation = true;
            "
            class="bereich"
            :class="{ active: bereiche.kooperation }"
          >
            <div class="letter">C</div>
            <div class="bereichsname">
              Kooperationsbereitschaft und Kontaktfähigkeit
            </div>
          </div>
          <div
            @click="
              hideAllBereiche();
              bereiche.belastbarkeit = true;
            "
            class="bereich"
            :class="{ active: bereiche.belastbarkeit }"
          >
            <div class="letter">D</div>
            <div class="bereichsname">Belastbarkeit</div>
          </div>
        </div>
        <div class="erklaerung" id="erklaerung">
          <div v-if="bereiche.interesse">
            <h4>A. Interesse und Leistungsanspruch</h4>
            <p>
              (Merkmale: A1. Neugier und Begeisterungsfähigkeit, A2.
              Gewissenhaftigkeit und Genauigkeit, A3. Ausdauer und
              Beharrlichkeit)
            </p>
            <p>
              Die drei dem Bereich Interesse und Leistungsanspruch zugeordneten
              Merkmale stellen sehr grundlegende persönliche
              Eignungsvoraussetzungen für ein erfolgreiches Studium der
              Informatik und die spätere Tätigkeit im Beruf dar. In jedem Fall
              sollten ausgeprägte Neugier und Begeisterungsfähigkeit für die
              Inhalte der Informatik vorhanden sein, ohne die sich keine
              anhaltende Freude am Studium und an der späteren beruflichen
              Tätigkeit in diesem Fachgebiet entwickeln kann. Neugier und
              Begeisterungsfähigkeit allein machen jedoch keine:n
              Informatiker:in aus: Hohe Leistungen können nur erreicht werden,
              wenn Gewissenhaftigkeit und Genauigkeit die Arbeitshaltung
              kennzeichnen und die Zielsetzungen mit viel Ausdauer und
              Beharrlichkeit verfolgt werden. Ohne Frage sind in allen drei
              Merkmalen höhere Werte wünschenswert.
            </p>
            <p>
              Was könnte der beurteilten Person empfohlen werden, um sich ihrer
              Interessen und ihres Leistungsanspruch zu versichern und diese
              weiterzuentwickeln? Einige mögliche Ansatzpunkte seien genannt:
            </p>

            <ul>
              <li>
                Einführende Literatur zur Informatik lesen; prüfen, ob es ihr
                gelingt, den Darstellungen mit Verständnis zu folgen und ob ihr
                Interesse durch das neu erworbene Wissen zunimmt
              </li>
              <li>
                Sich mit technischen und gesellschaftlichen Herausforderungen
                beschäftigen und versuchen herauszufinden, welche Rolle die
                Informatik bei deren Lösung spielt; prüfen, ob sie sich für
                derartige Fragen und Probleme begeistern kann
              </li>
              <li>
                Den eigenen Leistungsanspruch hinterfragen, insbesondere
                inwieweit sich der Anspruch an die Qualität der eigenen
                Arbeitsergebnisse mit dem geforderten deckt oder darüber
                hinausgeht; Rückmeldungen von anderen Menschen einholen und
                gezielt Feedback geben lassen, wenn sie sich nicht sicher ist
              </li>
              <li>
                Sich an Situationen erinnern, in denen sie über eine längere
                Zeit stark gefordert war, um bestimmte Ziele zu erreichen;
                herausfinden, wie es ihr gelang, Ausdauer und Beharrlichkeit zu
                entwickeln und aufrecht zu erhalten; aus diesen Erkenntnissen
                Schlussfolgerung für ihre Studienwahlentscheidung ableiten
              </li>
            </ul>

            <p>
              Die Merkmale aufgrund Ihrer Antworten in diesem Bereich sind wie
              folgt
            </p>
            <ul>
              <li>
                <div v-if="this.stanine[0].value">
                A1. Neugier und Begeisterungsfähigkeit: Stanine-Wert von
                {{ this.stanine[0].value }}, d.h.
                {{ this.returnAverageComparison(this.stanine[0].value) }} </div>
                <div v-else>
                A1. Neugier und Begeisterungsfähigkeit: Keine Einschätzung
                </div>
              </li>
              <li>
                <div v-if="this.stanine[1].value">
                A2. Gewissenhaftigkeit und Genauigkeit: Stanine-Wert von
                {{ this.stanine[1].value }}, d.h.
                {{ this.returnAverageComparison(this.stanine[1].value) }}</div>
                <div v-else>
                A2. Gewissenhaftigkeit und Genauigkeit: Keine Einschätzung
                </div>
              </li>
              <li>
                <div v-if="this.stanine[2].value">
                A3. Ausdauer und Beharrlichkeit: Stanine-Wert von
                {{ this.stanine[2].value }} d.h.
                {{ this.returnAverageComparison(this.stanine[2].value) }} </div>
                <div v-else>
                A3. Ausdauer und Beharrlichkeit: Keine Einschätzung
                </div>
              </li>
            </ul>
          </div>
          <div v-if="bereiche.auffassung">
            <h4>B. Auffassungsgabe und Arbeitsweise</h4>
            <p>
              (Merkmale: B1. Auffassungsgabe und analytisches Denken, B2.
              Selbstständigkeit und Eigenaktivität, B3. Fähigkeit zum
              effizienten Arbeiten)
            </p>
            <p>
              Gute kognitive Fähigkeiten und ein ökonomisches Arbeiten sind eine
              weitere Voraussetzung für ein erfolgreiches Studium. In der
              Informatik sind besonders eine gute Auffassungsgabe und
              ausgeprägtes analytisches Denken erforderlich, um Probleme
              systematisch durchdringen und geeignete Lösungen erarbeiten zu
              können. Die inhaltliche Auseinandersetzung mit den Studien- und
              Arbeitsanforderungen sollte ihre Ergänzung auch in einer
              entsprechenden Arbeitsweise finden. Gefordert sind
              Selbstständigkeit und Eigenaktivität, um mehr Tiefe bei der
              Beschäftigung mit den Inhalten zu erreichen, sowie ein effizientes
              Arbeiten. Deshalb sollten auch in diesen Merkmalen höhere Werte
              vorliegen.
            </p>
            <p>
              Um sich in diesen Bereichen weiterzuentwickeln, können folgende
              Anregungen hilfreich sein:
            </p>

            <ul>
              <li>
                Sich mit mathematischen Problemen beschäftigen und für sich
                prüfen, ob abstraktes Denken Freude bereitet; Probleme im Alltag
                suchen, die mathematisch gelöst werden können, und
                Lösungsmodelle dafür entwickeln
              </li>
              <li>
                Die eigene Arbeitsweise analysieren; Vorgaben und Anleitungen
                zur Lösung von Aufgaben als Rahmen sehen, der erweitert werden
                kann, um zu neuen Erkenntnissen zu gelangen
              </li>
              <li>
                Die eigene Arbeit so effizient gestalten, dass z. B. ausreichend
                Zeit für die Bewältigung eines größeren Arbeitsanfalls und für
                andere (persönliche) Dinge besteht; sich bewusst machen, wie
                viel Zeit für welche Tätigkeiten aufgewendet wird
              </li>
              <li>
                Die persönliche Arbeitsorganisation verbessern, sich mit anderen
                dazu austauschen; sich effektivere Arbeitstechniken aneignen,
                sich im Zeitmanagement und der Prioritätensetzung üben;
                Selbstdisziplin bei der Planung und Realisierung von Aufgaben
                stärken
              </li>
            </ul>

            <p>
              Die Merkmale aufgrund Ihrer Antworten in diesem Bereich sind wie
              folgt
            </p>
            <ul>
              <li>
                <div v-if="this.stanine[3].value">
                B1. Auffassungsgabe und analytisches Denken: Stanine-Wert von
                <strong>{{ this.stanine[3].value }}</strong
                >, d.h.
                {{ this.returnAverageComparison(this.stanine[3].value) }} </div>
                <div v-else>
                  B1. Auffassungsgabe und analytisches Denken: keine Einschätzung</div>
              </li>
              <li>
                <div v-if="this.stanine[4].value">
                B2. Selbstständigkeit und Eigenaktivität: Stanine-Wert von
                <strong>{{ this.stanine[4].value }}</strong
                >, d.h.
                {{ this.returnAverageComparison(this.stanine[4].value) }}</div>
                <div v-else>
                  B2. Selbstständigkeit und Eigenaktivität: keine Einschätzung</div>
              </li>
              <li>
                <div v-if="this.stanine[5].value">
                B3. Fähigkeit zum effizienten Arbeiten: Stanine-Wert von
                <strong>{{ this.stanine[5].value }}</strong
                >, d.h.
                {{ this.returnAverageComparison(this.stanine[5].value) }}</div>
                <div v-else>
                  B3. Fähigkeit zum effizienten Arbeiten: keine Einschätzung</div>

              </li>
            </ul>
          </div>
          <div v-if="bereiche.kooperation">
            <h4>C. Kooperationsbereitschaft und Kontaktfähigkeit</h4>
            <p>
              (Merkmale: C1. Bereitschaft zur Zusammenarbeit, C2. Soziales
              Geschick und Sensibilität)
            </p>
            <p>
              Es wäre falsch zu glauben, dass Studierende der Informatik und
              Informatiker:innen ihre Arbeitszeit allein vor dem Bildschirm
              verbringen. Vielmehr ist in der Regel eine enge Zusammenarbeit mit
              anderen gefordert. Eine ausgeprägte Bereitschaft dazu stellt eine
              wichtige Voraussetzung für eine erfolgreiche Bewältigung der
              Studien- und beruflichen Anforderungen dar. Gefordert sind daneben
              soziales Geschick und Sensibilität. Dies gilt in besonderem Maße,
              wenn anderen Berufsgruppen Probleme aus der Sicht der Informatik
              vermittelt werden sollen und ein gemeinsames Verständnis für deren
              Lösung zu finden ist. Deshalb sollten auch im
              sozial-kommunikativen Bereich deutliche Stärken vorhanden sein,
              die in höheren Werten im Profil ihren Ausdruck finden.
            </p>
            <p>
              Folgende Bemühungen können angebracht sein, um in diesem Bereich
              die Entwicklung zu fördern:
            </p>

            <ul>
              <li>
                Die Zusammenarbeit mit anderen suchen und in Projekten
                mitarbeiten; sich in Diskussionen einbringen und an der
                Lösungsfindung aktiv mitwirken
              </li>
              <li>
                Anderen bewusst zuhören; gezielt Fragen stellen, um das
                Gegenüber besser zu verstehen.
              </li>
              <li>
                Sich in der Gesprächsführung üben; lernen, in Diskussionen
                unterschiedliche Standpunkte auszutauschen und zusammenzuführen.
              </li>
              <li>
                Sich nicht hinter dem Computer vergraben, sondern direkten
                Kontakt mit anderen suchen.
              </li>
              <li>
                In Foren oder auf Plattformen im Internet bei Themen
                mitarbeiten, bei denen man sich gut auskennt; andere dort
                unterstützen, indem man Fragen beantwortet oder bei der Lösung
                von Problemen hilft.
              </li>
            </ul>

            <p>
              Die Merkmale aufgrund Ihrer Antworten in diesem Bereich sind wie
              folgt
            </p>
            <ul>
              <li>
                <div v-if="this.stanine[6].value">
                C1. Bereitschaft zur Zusammenarbeit: Stanine-Wert von
                <strong>{{ this.stanine[6].value }}</strong
                >, d.h.
                {{ this.returnAverageComparison(this.stanine[6].value) }}</div>
                <div v-else>
                  C1. Bereitschaft zur Zusammenarbeit: Keine Einschätzung
                </div>
              </li>
              <li>
                <div v-if="this.stanine[7].value">
                C2. Soziales Geschick und Sensibilität: Stanine-Wert von
                <strong>{{ this.stanine[7].value }}</strong
                >, d.h.
                {{ this.returnAverageComparison(this.stanine[7].value) }}</div>
                <div v-else>
                  C2. Soziales Geschick und Sensibilität: Keine Einschätzung
                </div>
              </li>
            </ul>
          </div>
          <div v-if="bereiche.belastbarkeit">
            <h4>D. Belastbarkeit</h4>
            <p>
              (Merkmale: D1. Resignationstendenz (bei Misserfolg), D2.
              Erholungs- & Entspannungsfähigkeit)
            </p>
            <p>
              Für die meisten Menschen gilt, dass ihnen im Studium wie auch im
              Beruf nicht alle Vorhaben uneingeschränkt gelingen. Misserfolge
              und Rückschläge werden wahrscheinlich nicht zur Gänze ausbleiben.
              Umso wichtiger ist es, nicht zu resignieren und in Passivität zu
              verfallen, wenn es nicht wie gewünscht läuft. Gute Erholungs- und
              Entspannungsfähigkeit sind wichtig für die Erhaltung der
              Leistungsfähigkeit und die langfristige Gewährleistung der
              Gesundheit. Eine hohe Ausprägung in der Resignationstendenz sowie
              eine geringe in der Erholungs- und Entspannungsfähigkeit lassen
              hier auf mögliche Defizite schließen. Auf der anderen Seite ist es
              aber nicht so, dass die jeweils niedrigste bzw. höchste Ausprägung
              in jedem Fall erforderlich ist.
            </p>
            <p>
              Was kann getan werden, wenn die Werte stärkere Einschränkungen in
              der psychischen Widerstandskraft und im gesundheitsförderlichen
              Verhalten anzeigen? Einige mögliche Ansatzpunkte seien genannt:
            </p>

            <ul>
              <li>
                Das Gespräch mit anderen (Mitschüler:innen, Kolleg:innen usw.)
                suchen, um sich darüber auszutauschen, wie diese Misserfolge und
                Rückschläge erleben und versuchen zu überwinden; gegenseitiger
                Austausch fördert emotionale Entlastung, das Erleben sozialer
                Unterstützung stärkt die Belastbarkeit
              </li>
              <li>
                Belastende Situationen gezielt angehen; die Bewältigung
                kritischer Situationen (z. B. Vorträge, Präsentationen, …) üben,
                um auf diese Weise kontinuierlich an Sicherheit zu gewinnen
              </li>
              <li>
                Der Neigung schnell aufzugeben versuchen entgegenzuwirken; sich
                an Situationen erinnern, in denen das nicht so war und Ursachen
                suchen; die gewonnene Erkenntnis nutzen, um sich in zukünftigen
                Situationen anders verhalten zu können
              </li>
              <li>
                Frühzeitig Hilfe suchen und alle mögliche Unterstützung in
                Anspruch nehmen, bevor sich die Schwierigkeiten anhäufen;
                professionelle Unterstützung nutzen, die für Schüler:innen und
                Studierende angeboten wird
              </li>
              <li>
                Die persönlichen Ansprüche, Erwartungen und Haltungen gegenüber
                Arbeit und Studium klären; ein ausgewogenes Verhältnis zwischen
                Arbeit und anderen Lebensanforderungen finden, das die
                langfristige Erhaltung der Gesundheit gewährleistet (s. a. B.
                Auffassungsgabe und Arbeitsweise).
              </li>
              <li>
                Entspannung lernen, sportliche und weitere Freizeitaktivitäten
                pflegen, auf Erholungs- und Regenerationsphasen im Alltag achten
              </li>
            </ul>

            <p>
              Die Merkmale aufgrund Ihrer Antworten in diesem Bereich sind wie
              folgt
            </p>
            <ul>
              <li>
                <div v-if="this.stanine[8].value">
                D1. Resignationstendenz (bei Misserfolg): Stanine-Wert von
                <strong>{{ this.stanine[8].value }}</strong
                >, d.h.
                {{ this.returnAverageComparison(this.stanine[8].value) }}</div>
                <div v-else>
                  D1. Resignationstendenz (bei Misserfolg): Keine Einschätzung
                </div>
              </li>
              <li>
                <div v-if="this.stanine[9].value">
                  D2. Erholungs- & Entspannungsfähigkeit: Stanine-Wert von
                  <strong>{{ this.stanine[9].value }}</strong
                  >, d.h.
                  {{ this.returnAverageComparison(this.stanine[9].value) }}
                </div>
                <div v-else>
                  D2. Erholungs- & Entspannungsfähigkeit: Keine Einschätzung
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p></p>

      <h3>3. Auswertungsgespräch mit der eingeschätzten Person</h3>
      <p>
        Nachdem die von Ihnen beurteilte Person Ihre Einschätzung erhalten hat,
        sollten Sie zu einem
        <em>Auswertungsgespräch</em> zusammenkommen. Bitte nehmen Sie sich dafür
        ausreichend Zeit und sorgen Sie für einen Rahmen, in dem eine ungestörte
        Aussprache möglich ist.
      </p>
      <p>
        Wir empfehlen für das Gespräch ein Vorgehen in folgenden fünf Schritten:
      </p>
      <ol>
        <li>
          Bitte erläutern Sie zunächst Ihre Fremdeinschätzung. (Sofern Sie nicht
          überall ein Urteil abgeben konnten, lassen Sie die betreffenden
          Merkmale aus.) Wichtig ist vor allem, dass Ihr Gegenüber Ihre
          Begründungen für die hohen und die niedrigen Werte erfährt. Beginnen
          Sie am besten mit den Merkmalen, in denen Sie die stärkeren Seiten
          Ihres Gegenübers sehen.
        </li>
        <li>
          Geben Sie Gelegenheit für die Erläuterung der Selbsteinschätzung (die
          Sie noch nicht kennen). Legen Sie dabei auch Ihrerseits besonderen
          Wert auf die Begründung der hohen und niedrigen Ausprägungen.
        </li>
        <li>
          Arbeiten Sie gemeinsam heraus, wo Übereinstimmungen und wo Differenzen
          (Abweichungen von 2 und mehr Skalenwerten) zwischen Selbst- und
          Fremdeinschätzung bestehen. Im Falle der abweichenden Einschätzungen
          sollten Sie gemeinsam nach den möglichen Ursachen suchen. (Auf welche
          Beobachtungen und Erfahrungen stützt sich z. B. das jeweilige Urteil?
          Welche Maßstäbe wurden angelegt?)
        </li>
        <li>
          Ermuntern Sie nun die von Ihnen beurteilte Person zusammenzufassen,
          was sie aus beiden Einschätzungen entnommen hat: Wo liegen die
          Stärken, auf die sie sich stützen kann? Wo gibt es Schwachpunkte, die
          überwunden werden sollten? Dabei sollte sich die beurteilte Person
          nicht nur an den einzelnen Merkmalen des Profils, sondern auch (und
          vor allem) an den übergreifenden Anforderungsbereichen A-D (s. o.)
          orientieren.
        </li>
        <li>
          Nehmen Sie schließlich auch darauf Einfluss, dass Ihr Gegenüber
          Schlussfolgerungen bezüglich geeigneter Entwicklungsmaßnahmen
          ableitet. Konkrete Anregungen für solche Maßnahmen finden Sie im
          obigen Text (Erläuterungen zu den vier Anforderungsbereichen).
        </li>
      </ol>
      <p class="footnote">
 </p>
    </div>
  </div>
</template>

<script>
import AssignmentbarFITiFe from "@/components/inf/fiti_fe/AssignmentbarFITiFe.vue";
import * as scrolling from "@/helpers/scroll-helper.js";
import Chart from "chart.js/auto";

export default {
  name: "FITiFeResults",
  components: {
    AssignmentbarFITiFe,
  },
  emits: [],
  props: {
    myFeId: Number,
    myFeCode: String,
  },
  data() {
    return {
      dataReady: false,
      showMissingAnswersNotification: false,
      replies: null,
      bereiche: {
        interesse: true,
        auffassung: false,
        kooperation: false,
        belastbarkeit: false,
      },
      // TODO: Stanine-Werte anpassen
      berechnungen: {
        A1: {
          name: "Neugier und Begeisterungsfähigkeit",
          summe: [1, 1, 1, 1, -1, 1],
          konstante: 6,
          stanine: [2.83, 3.33, 3.67, 4.0, 4.33, 4.67, 4.83, 5],
        },
        A2: {
          name: "Gewissenhaftigkeit und Genauigkeit",
          summe: [1, 1, -1, 1, 1],
          konstante: 6,
          stanine: [2.2, 2.8, 3.4, 3.8, 4.2, 4.6, 4.99, 5],
        },
        A3: {
          name: "Ausdauer und Beharrlichkeit",
          summe: [1, -1, 1, -1, 1, 1],
          konstante: 12,
          stanine: [2.33, 2.83, 3.33, 3.67, 4.0, 4.33, 4.67, 5],
        },
        B1: {
          name: "Auffassungsgabe und analytisches Denken",
          summe: [1, 1, -1, 1, 1],
          konstante: 6,
          stanine: [2.8, 3.0, 3.4, 3.6, 4.0, 4.2, 4.6, 4.8],
        },
        B2: {
          name: "Selbstständigkeit und Eigenaktivität",
          summe: [-1, -1, -1, -1, -1],
          konstante: 30,
          stanine: [1.6, 2.2, 2.8, 3.4, 3.8, 4.0, 4.4, 4.8],
        },
        B3: {
          name: "Fähigkeit zum effizienten Arbeiten",
          summe: [1, -1, 1, -1, 1, -1],
          konstante: 18,
          stanine: [2.33, 2.67, 3.0, 3.33, 3.67, 4.0, 4.33, 4.5],
        },
        C1: {
          name: "Bereitschaft zur Zusammenarbeit",
          summe: [1, 1, 1, -1, 1],
          konstante: 6,
          stanine: [2.0, 2.6, 3.2, 3.6, 4.0, 4.4, 4.8, 5],
        },
        C2: {
          name: "Soziales Geschick und Sensibilität",
          summe: [1, 1, 1, -1, 1],
          konstante: 6,
          stanine: [2.4, 2.8, 3.2, 3.6, 4.0, 4.4, 4.8, 5],
        },
        D1: {
          name: "Resignationstendenz (bei Misserfolg)",
          summe: [1, 1, 1, 1, -1],
          konstante: 6,
          stanine: [1.4, 1.8, 2.2, 2.8, 3.0, 3.6, 4.0, 4.4],
        },
        D2: {
          name: "Erholungs- & Entspannungsfähigkeit",
          summe: [1, -1, -1, 1, 1],
          konstante: 12,
          stanine: [1.8, 2.0, 2.4, 3.0, 3.6, 4.0, 4.6, 5],
        },
      },
      stanine: [
        { label: "A1", name: "Neugier und Begeisterungsfähigkeit", value: 9 },
        { label: "A2", name: "Gewissenhaftigkeit und Genauigkeit", value: 9 },
        { label: "A3", name: "Ausdauer und Beharrlichkeit", value: 9 },
        {
          label: "B1",
          name: "Auffassungsgabe und analytisches Denken",
          value: 9,
        },
        { label: "B2", name: "Selbstständigkeit und Eigenaktivität", value: 9 },
        { label: "B3", name: "Fähigkeit zum effizienten Arbeiten", value: 9 },
        { label: "C1", name: "Bereitschaft zur Zusammenarbeit", value: 9 },
        { label: "C2", name: "Soziales Geschick und Sensibilität", value: 9 },
        { label: "D1", name: "Resignationstendenz (bei Misserfolg)", value: 9 },
        { label: "D2", name: "Erholungs- & Entspannungsfähigkeit", value: 9 },
      ],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          r: {
            beginAtZero: true,
            min: 0,
            max: 10,
          },
        },
      },
    };
  },
  async mounted() {
    scrolling.scrollToElementByClassName("container", { behavior: "smooth" });
    await this.getMyResultFitiFe();
    this.assignStanine();
    new Chart("chart", this.dataset);
    this.dataReady = true;
  },
  methods: {
    async getMyResultFitiFe() {
      let params = {
        myFeCode: this.myFeCode,
      };

      try {
        const response = await fetch(
          process.env.VUE_APP_STRAPI_API_URL + "/api/inf-fiti-fe/get-my-fe",
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ data: params }),
          }
        );
        const res = await response.json();
        //console.log(res);
        if (!res || res.length == 0) {
          this.replies = "NO RESULTS FOUND";
        } else {
          this.replies = res.myFe[0].replies;
        }
      } catch (error) {
        console.log(error);
      }
    },
    hideAllBereiche() {
      this.bereiche.interesse = false;
      this.bereiche.auffassung = false;
      this.bereiche.kooperation = false;
      this.bereiche.belastbarkeit = false;
      let myDiv = document.getElementById("erklaerung");
      myDiv.scrollTop = 0;
    },
    calculateStanine(replies, berechnung) {
      let score = 0;
      let i = 0;

      let missingAnswers = 0;
      while (i < replies.length) {
        if (replies[i] == 0) {
          // missing answer
          missingAnswers++;
        }

        if (replies.length == 6 && missingAnswers > 2) {
          //max two missing
          this.showMissingAnswersNotification = true;
          return null;
        }
        if (replies.length == 5 && missingAnswers > 1) {
          //max one missing
          this.showMissingAnswersNotification = true;
          return null;
        }
        score = score + replies[i] * berechnung.summe[i];
        i++;
      }
      score = (score + berechnung.konstante) / replies.length;
      let j = 0;
      while (j < 9) {
        if (score > berechnung.stanine[j]) {
          j++;
        } else {
          break;
        }
      }
      return j + 1;
    },
    assignStanine() {
      try {
        if(this.replies == "NO RESULTS FOUND") throw new Error("NO RESULTS FOUND");
        if(this.replies.length == 0) throw new Error("NO RESULTS FOUND");
        if(this.replies == null) throw new Error("Replies is null");
        const subbereiche = [
          "A1",
          "A2",
          "A3",
          "B1",
          "B2",
          "B3",
          "C1",
          "C2",
          "D1",
          "D2",
        ];
        let i = 0;
        while (i < subbereiche.length) {
          this.stanine[i].value = this.calculateStanine(
            this.replies[subbereiche[i]],
            this.berechnungen[subbereiche[i]]
          );
          i++;
        }
      } catch (error) {
        console.error(error);
      }
    },
    returnAverageComparison(num) {
      if (num < 4) {
        return "unter dem Durchschnitt";
      } else if (num < 7) {
        return "ungefähr im Durchschnitt";
      } else {
        return "über dem Durchschnitt";
      }
    },
  },
  computed: {
    dataset() {
      return {
        type: "radar",
        data: {
          labels: this.stanine.map((a) => a.label.concat(". ", a.name)),
          datasets: [
            {
              label: "Ihr Ergebnis",
              data: this.stanine.map((a) => a.value),
              fill: true,
              backgroundColor: "rgba(54, 162, 235, 0.2)",
              borderColor: "rgb(54, 162, 235)",
              pointBackgroundColor: "rgb(54, 162, 235)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgb(54, 162, 235)",
            },
          ],
        },
        options: {
          scales: {
            r: {
              max: 9,
              min: 0,
              pointLabels: {
                font: {
                  size: 15,
                },
              },
            },
          },
        },
      };
    },
  },
};
</script>

<style scoped>
.footnote {
  font-size: x-small;
}
.erklaerungsbox {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  border: 1px solid black;
  height: 800px;
  padding-left: 0px;
}
.erklaerung {
  overflow: scroll;
  padding-left: 20px;
  padding-right: 20px;
}
.bereiche {
  min-width: 400px;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-right: 1px solid black;
  margin-right: 20px;
}
.bereich.active {
  font-weight: bold;
  color: #fff;
  background-color: #00446e;
}
.bereich {
  display: flex;
  flex-direction: row;
  height: 24.9%;
  align-items: center;
  color: #fbfeff;
  background-color: #006bac;
  width: 100%;
}
.letter {
  font-size: 50pt;
  margin-right: 20px;
  margin-left: 5px;
}
.bereichsname {
  font-size: 15pt;
  margin-right: 20px;
}
.bereich:hover {
  font-weight: bold;
  color: #fff;
  background-color: #00446e;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
p {
  line-height: 1.3;
}
.missing-answers-notification {
  display: flex;
  justify-content: center;
  border: 2px solid lightcoral;
  padding: 1em;
  margin-bottom: 2em;
  font-weight: bold;
}
</style>
