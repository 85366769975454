<template>
  <div class="container">
    <AssignmentbarFITiFe @end-assignment="alert('endAssignment')"
    :showHelpBtn="false"
    :showDismissBtn="true"
    :showFinishBtn="false">
    </AssignmentbarFITiFe>
    <div class="row">
      <h2>D1. Resignationstendenz (bei Misserfolg)</h2>
      <p>
        Weder im Studium noch im Beruf wird stets alles problemlos gelingen. Auch in der Informatik erweist sich die Lösung fachlicher Probleme nicht selten als schwierig und das gewünschte Ergebnis kann erst nach Umwegen und sogar zwischenzeitlichem Scheitern erreicht werden. Wichtig ist es deshalb, bei Misserfolgen nicht zu resignieren und auch nach Enttäuschungen hartnäckig zu bleiben und seine Ziele weiter zu verfolgen. 
      </p>
      <div class = "survey">
        <div class="survey-row header">
          <div class = "question">Wie ist das bei ihr/ihm?</div>
          <div class = "reply">trifft völlig zu</div>
          <div class = "reply">trifft überwiegend zu</div>
          <div class = "reply">trifft teilsteils zu</div>
          <div class = "reply">trifft überwiegend nicht zu</div>
          <div class = "reply">trifft überhaupt nicht zu</div>
          <div class = "reply">nicht beurteilbar</div>
        </div>

        <div class="survey-row light">
          <div class = "question">Wenn sich trotz ihrer/seiner Anstrengung kein Erfolg zeigt, wird sie/er misslaunig.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "0" v-model = "fragen.Q1"></div>
        </div>

        <div class="survey-row dark">
          <div class = "question">Ausbleibender Erfolg raubt ihr/ihm nachhaltig ihre/seine Kräfte.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "0" v-model = "fragen.Q2"></div>
        </div>

        <div class="survey-row light">
          <div class = "question">Misserfolge lösen oftmals das Gefühl der Niedergeschlagenheit bei ihr/ihm aus.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "0" v-model = "fragen.Q3"></div>
        </div>

        <div class="survey-row dark">
          <div class = "question">Wenn ihr/ihm etwas nicht gelingt, verliert sie/er schnell den Mut.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "0" v-model = "fragen.Q4"></div>
        </div>

        <div class="survey-row light">
          <div class = "question">Sie/Er lässt sich durch Misserfolge nicht aus der Bahn werfen. </div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "0" v-model = "fragen.Q5"></div>
        </div>

    </div>
    <div class="row notification-message">
      <p v-if="missingAnswers >= 2">
        Sie haben mehrere Fragen eines Merkmalsbereichs mit "nicht beurteilbar"
        beantwortet. Die Auswertung führt nur dann zu einem Ergebnis für diesen
        Bereich, wenn Sie nicht mehr als eine Fage in dieser Weise beantworten.
        Gehen Sie nur weiter, wenn Sie sich Ihrer Antworten sicher sind.
      </p>
      <p v-if="missingAnswers == 1">
        Sie haben eine Frage mit "nicht beurteilbar" beantwortet. Die Auswertung führt nur dann zu einem Ergebnis für diesen
        Bereich, wenn Sie nicht mehr als eine Fage in dieser Weise beantworten.
        Gehen Sie nur weiter, wenn Sie sich Ihrer Antworten sicher sind.
      </p>
    </div>
  </div>
</div>
</template>

<script>
import AssignmentbarFITiFe from "@/components/inf/fiti_fe/AssignmentbarFITiFe.vue";
import * as scrolling from "@/helpers/scroll-helper.js";
import store from "@/store";

export default {
  name: "FitiFeD1",
  components: {
    AssignmentbarFITiFe
},
  emits: ["enableWeiter", "disableWeiter"],
  mounted() {
    scrolling.scrollToElementByClassName("container", { behavior: "smooth" }); 
    this.fragen.Q1 = this.$store.state.fitiFeModule.replies.D1[0];
    this.fragen.Q2 = this.$store.state.fitiFeModule.replies.D1[1];
    this.fragen.Q3 = this.$store.state.fitiFeModule.replies.D1[2];
    this.fragen.Q4 = this.$store.state.fitiFeModule.replies.D1[3];
    this.fragen.Q5 = this.$store.state.fitiFeModule.replies.D1[4]; 
    this.$emit("disableWeiter");
  },
  updated() {
    if (this.unfinishedAnswers == 0) {
      this.$emit("enableWeiter");
    }
  },
  created() {
    // add watcher to count missing answers
    for (const key in this.fragen) {
      this.$watch(`fragen.${key}`, (newValue, oldValue) => {
        if(newValue==0){
          this.missingAnswers++;
        }
        if(oldValue==0 && newValue!=0){
          this.missingAnswers--;
        }
        if((oldValue == null || oldValue == undefined) && (newValue != null && newValue != undefined)){
          this.unfinishedAnswers--;
        }
      });
    }
  },
  unmounted() {
    if (this.unfinishedAnswers == 0) {
      this.logUpdate(this.fragen);
    }
  },
  data() {
    return {
      fragen: {
        Q1: null,
        Q2: null,
        Q3: null,
        Q4: null,
        Q5: null
      },
      missingAnswers: 0,
      unfinishedAnswers: 5
    };
  },
  methods: {
       logUpdate(data) {
      store.commit("fitiFeD1Update", data);
    },
  }
}
</script>

<style>
  .survey {
  display: flex;
  flex-direction: column;
  }
  .survey-row {
    display: flex;
    flex-direction: row;
  }
  .question {
    min-width: 100px;
    width: 25%;
    max-width: 500px;
  }
  .reply {
    min-width: 100px;
    width: 15%;
    max-width: 300px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .dark {
    background-color: rgb(173, 201, 230);
  }
  .light{
    background-color: rgb(248, 248, 248);
  }
  .header {
    background: rgb(70, 110, 165);
    color: white;
    justify-content: space-between;
    gap: 30px;
  }
</style>