<template>
  <div class="container mb-2 pb-2">
    <div class="button-bar assignment-bar">
      <!-- Button trigger modal -->
      <div class="button-bar-left">
        <!-- empty div to align buttons to right -->
      </div>
      <div class="button-bar-right">
        <div
          type="button"
          @click="openQuitModal"
          class="custom-btn-secondary dismiss-btn"
          data-bs-toggle="modal"
          data-bs-target="#modal"
        >
          „FIT-I“ abbrechen
        </div>
      </div>
    </div>

    <BaseModal>
      <template v-slot:header>
        <div class="modal-title">
          <!-- quit modal title -->
          <div v-if="showModal === 'quit'">„FIT-I“ abbrechen</div>
          <div v-else>Error</div>
        </div>
      </template>

      <template v-slot:body>
        <!-- quit modal body -->
        <div v-if="showModal === 'quit'">
          <p>
            Wollen Sie „FIT-I“ abbrechen? Bitte beachten Sie, dass Sie noch
            nicht alle Teile bearbeitet haben.
          </p>
          <p>
            Falls Sie dennoch abbrechen möchten, teilen Sie uns bitte Ihren
            Grund mit:
          </p>
          <form>
            <div class="form-group">
              <textarea class="form-control" id="message-text"></textarea>
            </div>
          </form>
        </div>
        <div v-else>Error loading text</div>
      </template>

      <template v-slot:footer>
        <div v-if="showModal === 'help'">
          <button type="button" class="btn custom-btn" data-bs-dismiss="modal">
            OK
          </button>
        </div>
        <!-- quit modal -->
        <div v-else-if="showModal === 'quit'">
          <button
            type="button"
            class="btn custom-btn-secondary"
            data-bs-dismiss="modal"
            @click="endFITi"
          >
            „FIT-I“ fortsetzen
          </button>
          <button type="button" class="btn custom-btn-danger" data-bs-dismiss="modal" @click="endFITi">
            Ja,„FIT-I“ abbrechen
          </button>
        </div>
        <!-- finish modal -->
        <div v-else>
          <button type="button" class="btn custom-btn" data-bs-dismiss="modal">
            Schließen
          </button>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";

export default {
  name: "Assignmentbar",
  components: {
    BaseModal,
  },
  data() {
    return {
      showModal: "",
    };
  },
  props: {},
  emits: ["end-fiti"],
  mounted() {},
  methods: {
    openQuitModal() {
      this.showModal = "quit";
    },
    endFITi() {
      let messageText = document.getElementById("message-text").value;
      if (messageText.length > 1) {
        this.saveQuitMessage(messageText);
      }
      this.$emit("end-fiti-fe");
      this.$router.push("/");
    },
    async saveQuitMessage(messageText) {
      try {
        await fetch(
          process.env.VUE_APP_STRAPI_API_URL + "/api/inf-fiti-fe-quit-messages",
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              data: {
                message: messageText,
              },
            }),
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped></style>
